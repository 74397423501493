import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { get, isBoolean, startCase } from 'lodash';
import moment from 'moment';
import { AlertBox, ButtonV2, Card, Typography } from '@vartanainc/design-system';

import { useMutation, useReactiveVar } from '@apollo/client';
import { PaymentAndFundingInfoRequired } from '../PaymentAndFundingInvoice/PaymentAndFundingInfoRequired';
import { POReviewRequired } from './ReviewPurchaseOrder/POReviewRequired';
import { APPROVE_CHECKOUT, SWITCH_ORDER_PROPOSAL } from '../../../graphql/queries/order';
import { OrderShowContext } from '../../../context/OrderContext';

import {
  getStatusClass,
  humanTimeFormat,
  formatCapital,
  getTextForNumberOfDays,
  getUSDateFormat,
  showToast,
  titleize,
} from '../../../utils/helpers';
import {
  editOrderType,
  orderType,
  labelValueField,
  summaryModes,
  orderStates,
  editNotAllowedStates,
} from '../../../constants/common.constants';
import ApprovalRequired from './ActionRequired/ApprovalRequired';
import {
  defaultFinancingInformationValues,
  ALLOWED_STATES_TO_EXPIRE,
} from '../order.constants';
import { ACTION, RESOURCE } from '../../../constants/permissions';
import AutoLoad from '../../../components/AutoLoad';
import { HasPermission } from '../../../components/HasPermission/HasPermission';
import ViewAccountOwner from '../../Common/AccountOwner/ViewAccountOwner';
import ReassignAccountOwner from '../../Common/AccountOwner/reassign';
import usePermissions from '../../../utils/hooks/permissions';
import VendorSignatureRequired from './VendorSignatureRequired/VendorSignatureRequired';
import OrderExpired from '../OrderExpired';
import { ReactComponent as OrderCartIcon } from '../../../assets/shopping-cart-32p.svg';
import { ReactComponent as SignatureIcon } from '../../../assets/signature-32.svg';
import ResellersCard from './OrderResellers/ResellersCard';
import OrderProposalSummaryV2 from '../OrderSummary/OrderProposalSummaryV2';
import { sessionVar } from '../../../graphql/cache';
import { COUNTRY_TO_CURRENCY_MAP } from '../../../static';
import {
  ORDER_POST_FINANCED_STATES,
  ORDER_STATES_TO_SHOW_MILESTONES_PAYOUT,
} from '../../../constants/order';

const sections = ['summary', 'reassignAccountExecutive'];

function Summary({ orderData, crmOpportunityId }) {
  const navigate = useNavigate();
  const [, hasPermission] = usePermissions();

  const [selectedSection, setSelectedSection] = useState(sections[0]);
  const [selectedProposal, setSelectedProposal] = useState();
  const [approvingOrder, setApprovingOrder] = useState(false);
  const [financingInformation, setFinancingInformation] = useState(
    defaultFinancingInformationValues
  );
  const [showVendorSignature, setShowVendorSignature] = useState(false);

  const { refetchOrderData, orderDataLoading, selectedCustomer } =
    useContext(OrderShowContext);

  const [approveOrder] = useMutation(APPROVE_CHECKOUT);
  const [selectOrderProposal, { loading: selectingOrderProposal }] =
    useMutation(SWITCH_ORDER_PROPOSAL);

  const orderState = get(orderData, 'state', '');

  const sessionData = useReactiveVar(sessionVar);

  const firstMilestone = get(orderData, 'milestones[0]', {});

  useEffect(() => {
    setShowVendorSignature(orderState === 'vendor_signature');
  }, [orderState]);

  useEffect(() => {
    const defaultProposal = get(orderData, 'orderProposals', [])?.find(
      (proposal) => proposal.isSelected
    );
    setSelectedProposal(defaultProposal);
    const vendorSummaryObj = {};
    get(orderData, 'vendorSummary', []).forEach((el) => {
      const key = el[0];
      const val = el[1];
      vendorSummaryObj[key] = val;
    });

    setFinancingInformation({
      totalContractValue: vendorSummaryObj.total_contract_value || '--',
      currency: vendorSummaryObj.currency || '--',
      availableCredit: vendorSummaryObj.available_credit || '--',
      paymentTerm: vendorSummaryObj.contract_length || '--',
      startDate:
        vendorSummaryObj.start_date &&
        moment(vendorSummaryObj.start_date).format('MM/DD/YY'),
      endDate:
        vendorSummaryObj.end_date && moment(vendorSummaryObj.end_date).format('MM/DD/YY'),
      paymentDue: vendorSummaryObj.payment_due || '--',
      customerApplicationFee: vendorSummaryObj.customer_application_fee || '--',
      vendorApplicationFee: vendorSummaryObj.vendor_application_fee || '--',
      paymentFrequencyLabel: get(defaultProposal, 'billingFrequency', null) || null,
      paymentFrequency: vendorSummaryObj.payments || '--',
      formattedVendorFee: vendorSummaryObj.formatted_vendor_fee || '--',
      netPayout: vendorSummaryObj.net_payout || '--',
      totalFees: vendorSummaryObj.total_fees || '--',
      isFullBuyout: vendorSummaryObj.is_full_buyout,
      payoffDiscount: vendorSummaryObj.buyout_discount || '--',
      netPayoff: vendorSummaryObj.net_payoff || '--',
      spiffAmount: get(orderData, 'formattedSpiffAmount', '0'),
      spiffRate: get(orderData, 'spiffRate', 0),
    });
  }, [orderData]);

  const handleOrderEdit = useCallback(() => {
    navigate(crmOpportunityId ? '/forms/order/edit' : '/dashboard/orders/edit', {
      state: {
        selectedOrder: orderData,
        editType: editOrderType.editOrder,
        crmOpportunityId,
        companyNumber: get(orderData, 'company.number', ''),
      },
    });
  }, [crmOpportunityId, navigate, orderData]);

  const handleOrderAuthSignerEdit = useCallback(() => {
    navigate(crmOpportunityId ? '/forms/order/edit' : '/dashboard/orders/edit', {
      state: {
        selectedOrder: orderData,
        editType: editOrderType.editAuthSigner,
        crmOpportunityId,
        companyNumber: get(orderData, 'company.number', ''),
      },
    });
  }, [crmOpportunityId, navigate, orderData]);

  const handleUpdateInputSelect = async (selectedProposalParam) => {
    const newSelectedProposal = get(orderData, 'orderProposals', []).find(
      (proposal) => proposal.number === selectedProposalParam.value
    );
    setSelectedProposal(newSelectedProposal);
    if (get(selectedProposal, 'number', null) !== selectedProposalParam.value) {
      const { data } = await selectOrderProposal({
        variables: { proposalNumber: newSelectedProposal.number },
      });
      if (data?.switchOrderProposal) {
        await refetchOrderData();
      }
    }
  };

  const poReviewRequired = (
    <HasPermission
      resource="order"
      action="approve_purchase_order"
      customer={selectedCustomer}
    >
      <POReviewRequired orderNumber={orderData?.number} />
    </HasPermission>
  );

  const { canUploadFundingInvoice, milestones, state } = orderData || {};

  const canUploadInvoice =
    canUploadFundingInvoice ||
    (milestones &&
      ORDER_STATES_TO_SHOW_MILESTONES_PAYOUT.includes(state) &&
      milestones.length > 1);

  const fundingInvoice = canUploadInvoice && (
    <PaymentAndFundingInfoRequired
      orderNumber={orderData?.number}
      isCadOrder={get(orderData, 'currency', '') === COUNTRY_TO_CURRENCY_MAP.CA}
    />
  );

  // for normal vendor case or in case reseller has product
  // we use the flag from session product
  const multipleCountriesEnabledonSession = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled'
  );
  // in case product does not have the flag we use the flag from vendor product
  const multipleCountriesEnabledonVendorProduct = get(
    orderData,
    'company.seller.product.multipleCountriesEnabled',
    false
  );

  // using the isBoolean to check if the flag is present in the session product
  // even if it is false. it will take priority over the vendor product flag
  const multipleCountriesEnabled = isBoolean(multipleCountriesEnabledonSession)
    ? multipleCountriesEnabledonSession
    : multipleCountriesEnabledonVendorProduct;

  const vendorSignature = useMemo(() => {
    if (get(orderData, 'state', '') === 'vendor_signature' && showVendorSignature) {
      let updatedAt = get(orderData, 'updatedAt', null);
      if (updatedAt) updatedAt = humanTimeFormat(get(orderData, 'updatedAt', ''));
      return (
        <HasPermission
          resource={RESOURCE.order}
          action={ACTION.counter_sign_document}
          customer={selectedCustomer}
        >
          <VendorSignatureRequired
            orderNo={get(orderData, 'number', '')}
            testMode={get(orderData, 'company.seller.testMode', false)}
            updatedAt={updatedAt}
            onSigned={() => setShowVendorSignature(false)}
            isTrack={false}
          />
        </HasPermission>
      );
    }
    return null;
  }, [orderData, selectedCustomer, showVendorSignature]);

  const approvalAction = useMemo(() => {
    const canApprove =
      hasPermission('order', 'approve', selectedCustomer) &&
      get(orderData, 'vendorState', '') === 'approval_required';

    const handleApproveOrder = async () => {
      try {
        setApprovingOrder(true);
        await approveOrder({
          variables: {
            number: get(orderData, 'number', ''),
          },
        });
        await refetchOrderData();
        setApprovingOrder(false);
        showToast(
          'success',
          'Order approved! The checkout link will be sent to the customer.'
        );
      } catch (error) {
        setApprovingOrder(false);
      }
    };

    if (canApprove) {
      return (
        <ApprovalRequired
          onPrimaryClick={handleApproveOrder}
          onSecondaryClick={handleOrderEdit}
          secondaryClickAllowed={hasPermission('order', 'update', selectedCustomer)}
          timeStamp={humanTimeFormat(get(orderData, 'createdAt', ''))}
          isLoading={approvingOrder}
        />
      );
    }
    return null;
  }, [
    hasPermission,
    selectedCustomer,
    orderData,
    approveOrder,
    refetchOrderData,
    handleOrderEdit,
    approvingOrder,
  ]);
  const isEditOrderAllowed = useMemo(() => {
    const isPgRequired = get(orderData, 'company.creditAppraisal.pgRequired', false);
    return !editNotAllowedStates.includes(get(orderData, 'state', null)) && !isPgRequired;
  }, [orderData]);

  // Order Validity message - shown when order is in expirable state
  const isUrgent = get(orderData, 'validForDays', '-') <= 15;
  const orderValidityMessage = ALLOWED_STATES_TO_EXPIRE.includes(orderState) && (
    <div className="w-full">
      <AlertBox
        backgroundColor={isUrgent ? 'warn' : 'info'}
        variant="alert-box"
        content={(
          <>
            Your order is valid{' '}
            {get(orderData, 'validForDays', null) > 1 ? 'for the next' : 'until'}{' '}
            {getTextForNumberOfDays(get(orderData, 'validForDays', '-'))},{' '}
            {get(orderData, 'validUntil')}{' '}
          </>
        )}
      >
      </AlertBox>
    </div>
  );

  // Order Expiry Message - shown after an order has expired
  const orderExpiryMessage = orderState === 'expired' && (
    <div className="px-2 py-2 bg-vartana-gray-20 text-vartana-gray-70">
      <Typography variant="paragraph12">
        {' '}
        Order expired on {get(orderData, 'validUntil')}.
      </Typography>
    </div>
  );

  return (
    <>
      {selectedSection === sections[0] ? (
        <div className="flex gap-4 h-full w-full">
          <div className="flex flex-col gap-4 lg:w-7/12">
            {orderData?.state === orderStates.review_purchase_order && poReviewRequired}
            {fundingInvoice}
            {approvalAction}
            {vendorSignature}
            {orderState === 'expired' && (
              <OrderExpired
                company={get(orderData, 'company', {})}
                variant={crmOpportunityId ? 'widget' : 'vendor'}
              />
            )}
            <Card
              variant="fullWidth"
              icon={<OrderCartIcon />}
              title={(
                <Typography variant="heading18" bold color="color-black-100">
                  Order summary
                </Typography>
              )}
              content={(
                <div className="flex flex-col divide-y gap-6">
                  <div className="flex flex-col gap-6">
                    <div className="grid grid-cols-2 gap-4">
                      {labelValueField('Order number', get(orderData, 'number', '-'))}
                      {labelValueField(
                        'Customer',
                        titleize(get(orderData, 'company.name', '-')),
                        crmOpportunityId
                          ? null
                          : `/dashboard/customers/${get(
                            orderData,
                            'company.number',
                            ''
                          )}/summary`
                      )}
                      <div className="grid auto-rows-min gap-y-1">
                        <div className="flex gap-1">
                          <p className="break-all vartana-p-small-bold text-vartana-gray-50">
                            Order status
                          </p>
                        </div>
                        <p>
                          <span
                            className={getStatusClass(get(orderData, 'vendorState', '-'))}
                          >
                            {startCase(get(orderData, 'vendorState', '-'))}
                          </span>
                        </p>
                      </div>
                      <div className="grid auto-rows-min gap-y-1">
                        <div className="flex gap-1">
                          <p className="break-all vartana-p-small-bold text-vartana-gray-50">
                            Credit decision
                          </p>
                        </div>
                        <p>
                          <span
                            className={getStatusClass(
                              get(orderData, 'creditAssessment.loanDecision', '-')
                            )}
                          >
                            {startCase(
                              get(orderData, 'creditAssessment.loanDecision', '-')
                            )}
                          </span>
                        </p>
                      </div>
                    </div>

                    {orderValidityMessage}
                    {orderExpiryMessage}
                  </div>
                  <div className="grid grid-cols-2 gap-6 pt-6 relative">
                    {labelValueField(
                      'Created on',
                      getUSDateFormat(get(orderData, 'formattedCreatedAt', ''))
                    )}
                  </div>
                </div>
              )}
            >
            </Card>
            <Card
              variant="fullWidth"
              icon={<SignatureIcon />}
              title={(
                <div className="flex justify-between items-center w-full">
                  <Typography variant="heading18" bold color="color-black-100">
                    Authorized signer
                  </Typography>
                  {isEditOrderAllowed && (
                    <HasPermission
                      resource={RESOURCE.order}
                      action={ACTION.update}
                      customer={selectedCustomer}
                    >
                      <ButtonV2
                        variant={{ type: 'ghost', typography: 'paragraph12' }}
                        text="Edit"
                        onClick={() => handleOrderAuthSignerEdit()}
                      />
                    </HasPermission>
                  )}
                </div>
              )}
              content={(
                <div className="flex flex-col divide-y gap-6">
                  <div className="grid grid-cols-2 gap-6 relative">
                    {labelValueField(
                      'First name',
                      formatCapital(get(orderData, 'company.primaryUser.firstName', '-'))
                    )}
                    {labelValueField(
                      'Last name',
                      formatCapital(get(orderData, 'company.primaryUser.lastName', '-'))
                    )}
                    {labelValueField(
                      'Email',
                      get(orderData, 'company.primaryUser.email', '-')
                    )}
                    {labelValueField(
                      'Phone',
                      get(orderData, 'company.primaryUser.formattedPhone', '-')
                    )}
                    {labelValueField(
                      'Job title',
                      get(orderData, 'company.primaryUser.formattedTitle', '-')
                    )}
                  </div>
                </div>
              )}
            >
            </Card>

            <ViewAccountOwner
              resource="order"
              customer={selectedCustomer}
              user={get(orderData, 'user', {})}
              openReassignAccountOwnerSection={() => setSelectedSection(sections[1])}
              allowReassignAccount={orderState !== 'expired'}
            />
            <ResellersCard resellers={get(orderData, 'resellers', [])} />
          </div>
          {!get(orderData, 'hideOrderDetailsForCollaborator', false) ? (
            <div className="flex flex-col lg:w-5/12 ">
              <Card
                variant="variable"
                content={(
                  <div>
                    <AutoLoad
                      loading={selectingOrderProposal || orderDataLoading}
                      containerClassName="flex justify-center h-full"
                    >
                      <OrderProposalSummaryV2
                        financingInformation={financingInformation}
                        selectedProposalId={get(selectedProposal, 'number', '')}
                        currentOrderType={get(
                          selectedProposal,
                          'orderType',
                          orderType.installments
                        )}
                        vartanaFinancingSelected={get(
                          selectedProposal,
                          'useVartanaFinancing',
                          false
                        )}
                        billingMethods={get(
                          orderData,
                          'company.availablePaymentMethods',
                          []
                        )}
                        currentMode={summaryModes.orderDetails}
                        orderMeta={{
                          id: get(orderData, 'id', ''),
                          number: get(orderData, 'number', ''),
                          status: get(orderData, 'vendorState', 'pending'),
                          resellerCreatedOrder: get(orderData, 'resellerCreatedOrder'),
                          milestoneNumber: get(firstMilestone, 'number', ''),
                        }}
                        documents={get(orderData, 'userDocuments', [])}
                        orderTitles={get(orderData, 'orderProposals', [])
                          ?.sort(
                            (proposalA, proposalB) =>
                              proposalA.sortOrder - proposalB.sortOrder
                          )
                          ?.map((proposal) => {
                            return {
                              label: proposal.title,
                              value: proposal.number,
                            };
                          })}
                        onEditOrder={() => handleOrderEdit()}
                        onInputSelect={(selectedOption) =>
                          handleUpdateInputSelect(selectedOption)}
                        currentOrderState={get(orderData, 'state', null)}
                        currentOrderNumber={get(orderData, 'number', null)}
                        companyName={formatCapital(get(orderData, 'company.name', ''))}
                        companyNumber={get(orderData, 'company.number', '')}
                        isPreFinanced={
                          get(orderData, 'paymentTerm', null) === 0 &&
                          !ORDER_POST_FINANCED_STATES.includes(
                            get(orderData, 'order.status', null)
                          )
                        }
                        invoiceEnabled={get(orderData, 'company.invoiceEnabled', false)}
                        paymentType={get(orderData, 'paymentType', '')}
                        currency={get(orderData, 'currency', '')}
                        multipleCountriesEnabled={multipleCountriesEnabled}
                      />
                    </AutoLoad>
                  </div>
                )}
              >
              </Card>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <ReassignAccountOwner
          resource={{
            name: get(orderData, 'number'),
            number: get(orderData, 'number'),
          }}
          assignedUser={get(orderData, 'user', {})}
          handleClose={async () => {
            setSelectedSection(sections[0]);
            await refetchOrderData();
          }}
        />
      )}
    </>
  );
}

Summary.propTypes = {
  orderData: PropTypes.shape({
    number: PropTypes.string,
    status: PropTypes.string,
    state: PropTypes.string,
    validForDays: PropTypes.number,
    formattedAmount: PropTypes.string,
    formattedFee: PropTypes.string,
    formattedFeePercentage: PropTypes.string,
    term: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    startDate: PropTypes.string,
    approvedAt: PropTypes.string,
    commencedAt: PropTypes.string,
    loanDecision: PropTypes.string,
    pgStatus: PropTypes.string,
    canUploadFundingInvoice: PropTypes.bool,
    formattedSpiffAmount: PropTypes.string,
    spiffAmount: PropTypes.number,
    spiffRate: PropTypes.number,
    userDocuments: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    acceptanceMode: PropTypes.string,
    milestones: PropTypes.arrayOf(
      PropTypes.shape({
        vendorMilestoneStatus: PropTypes.string,
        number: PropTypes.string,
      })
    ),
  }),
  crmOpportunityId: PropTypes.string,
};

Summary.defaultProps = {
  orderData: {
    status: '',
    formattedAmount: '',
    formattedFee: '',
    formattedFeePercentage: '',
    term: '',
    startDate: '',
    approvedAt: '',
    commencedAt: '',
    loanDecision: '',
    pgStatus: 'needed',
    userDocuments: [],
    formattedSpiffAmount: null,
    spiffAmount: null,
    spiffRate: 0,
    paymentType: '',
    acceptanceMode: '',
    milestones: null,
  },
  crmOpportunityId: '',
};

export default Summary;
