import { gql } from '@apollo/client';

export const CREATE_DIRECT_UPLOADS = gql`
  mutation createDirectUploads($directUploads: [DirectUploadInput!]!) {
    createDirectUploads(directUploads: $directUploads) {
      directUploads {
        url
        headers
        blobId
        signedBlobId
      }
    }
  }
`;

export const ATTACH_BLOBS_RESOURCE = gql`
  mutation attachBlobsResource(
    $resourceId: ID!
    $resourceType: String!
    $documentBlobs: [DocumentBlobInput!]!
    $milestoneNumber: String
  ) {
    attachBlobsResource(
      resourceId: $resourceId
      resourceType: $resourceType
      documentBlobs: $documentBlobs
      milestoneNumber: $milestoneNumber
    ) {
      session {
        id
      }
      resource {
        ... on Order {
          number
        }
      }
    }
  }
`;

export const ATTACH_ORDER_PROPOSAL_BLOB = gql`
  mutation attachOrderProposalBlob($blobId: String!, $uuid: String!) {
    attachBlobOrderProposal(blobId: $blobId, uuid: $uuid) {
      id
    }
  }
`;

export const ATTACH_PROPOSAL_VENDOR_DOCS = gql`
  mutation ($documentBlobs: [ProposalDocumentBlobInput!]!) {
    attachVendorDocsForProposals(documentBlobs: $documentBlobs) {
      id
    }
  }
`;

export const REPLACE_DOCUMENT_BLOBS_RESOURCE = gql`
  mutation replaceDocumentBlobsResource(
    $resourceId: ID!
    $resourceType: String!
    $documentReplaceBlobs: [DocumentBlobReplaceInput!]!
  ) {
    replaceDocumentBlobsResource(
      resourceId: $resourceId
      resourceType: $resourceType
      documentReplaceBlobs: $documentReplaceBlobs
    ) {
      session {
        id
      }
      resource {
        ... on Order {
          number
        }
      }
    }
  }
`;

export const ATTACH_BLOBS = gql`
  mutation attachBlobs($attachBlobs: [AttachBlobInput!]!) {
    attachBlobs(attachBlobs: $attachBlobs) {
      id
      order {
        id
      }
    }
  }
`;

export const CREATE_DIRECT_UPLOADS_CREDIT_APPRAISAL = gql`
  mutation createDirectUploadsCreditAppraisal(
    $directUploads: [DirectUploadInputCreditAppraisal!]!
  ) {
    createDirectUploadsCreditAppraisal(directUploads: $directUploads) {
      directUploads {
        url
        headers
        blobId
        signedBlobId
      }
    }
  }
`;

export const ATTACH_BLOBS_CREDIT_APPRAISAL = gql`
  mutation attachBlobsCreditAppraisal($attachBlobs: [AttachBlobInputCreditAppraisal!]!) {
    attachBlobsCreditAppraisal(attachBlobs: $attachBlobs) {
      id
    }
  }
`;

export const ATTACH_BLOBS_CUSTOMER = gql`
  mutation attachBlobsCustomer($attachBlobs: [AttachBlobInputCustomer!]!) {
    attachBlobsCustomer(attachBlobs: $attachBlobs) {
      id
    }
  }
`;
