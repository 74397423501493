import {
  camelCase,
  lowerCase,
  capitalize,
  mapKeys,
  startCase,
  toLower,
  get,
} from 'lodash';
import { FileChecksum } from '@rails/activestorage/src/file_checksum';
import moment from 'moment';
import 'moment-timezone';
import { toast } from 'react-toastify';
import { useFlags } from 'launchdarkly-react-client-sdk';
import countries from 'iso-3166-1-alpha-2';

import { PDFDocument } from 'pdf-lib';
import featureFlags from './feature_flags.json';
import {
  PAGES,
  SELLER_TYPE,
  SPAM_ALERTS,
  widgetBaseUrls,
  INPUT_MAX_LENGTHS,
  KEYS,
} from '../constants/common.constants';
import CustomNotification from '../components/CustomNotification';
import { navigationService } from '../graphql/navigation.service';
import { APP_NAMES } from '../pages/CommonWidgetV2/commonWidget.constants';
import CanadaFlag from '../assets/canada-flag.svg';
import USAFlag from '../assets/usa-flag.svg';
import { COUNTRY, COUNTRY_NAME_TO_STATES_MAP, COUNTRY_TO_CURRENCY_MAP } from '../static';

// Returns true if env is dev, staging or preview.
export function isLocalEnvironment() {
  return ['development', 'staging', 'preview', 'eksstaging', 'qa'].includes(
    process.env.REACT_APP_NODE_ENV
  );
}

// Returns true is $0 amount is passed as string
export const isZero = (str) => {
  const isZeroRegex = /^\$0(\.0{0,2})?$/;
  return isZeroRegex.test(str);
};

export const formatCurrency = (currency) => {
  const regex = /\.00$/;
  return regex.test(currency) ? currency.replace(regex, '') : currency;
};

export const reportError = (errorMessage, info = '') => {
  if (!errorMessage) {
    return; // Skip function execution if errorMessage is not truthy
  }

  if (SPAM_ALERTS.some((spam) => errorMessage.includes(spam))) {
    navigationService.rollbar.info(errorMessage, info);
  } else {
    navigationService.rollbar.error(errorMessage, info);
  }

  console.error(errorMessage, info);
};

export const initializeNullValuesToEmptyStrings = (obj) =>
  JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? '' : v)));

export const convertKeysToCamelCase = (obj = {}) => mapKeys(obj, (v, k) => camelCase(k));

/**
 * Takes a list of values & converts them to Enum
 *
 * @param {Array} values
 * @param {boolean} [camelize]
 * @returns {Object}
 */
export const createEnum = (values, camelize = true) => {
  const obj = {};
  values.forEach((value) => {
    if (camelize) obj[camelCase(value)] = value;
    else obj[value] = value;
  });
  return obj;
};

export const cleanPhoneNumber = (phoneNumber = '') => {
  const cleaned = phoneNumber
    .replace(/\s+/g, '') // remove spaces
    .replace(/[\])}[{(]/g, '') // remove braces ()
    .replace(/-/g, ''); // remove hyphens
  return cleaned;
};

export const getStatusClass = (status) => {
  const statusLowerCase = lowerCase(status);
  let className = '';
  switch (statusLowerCase) {
  case 'paid':
  case 'approved':
  case 'posted':
  case 'active':
  case 'yes':
  case 'completed':
  case 'extra charge accepted':
    className = 'pill success';
    break;
  case 'checkout':
  case 'application':
  case 'agreement':
  case 'invoice':
  case 'invoiced':
    className = 'pill primary';
    break;
  case 'canceled':
  case 'expired':
    className = 'pill canceled';
    break;
  case 'late':
  case 'inactive':
  case 'no':
  case 'chargeback':
    className = 'pill canceled';
    break;
  case 'charged off':
    className = 'pill danger';
    break;
  case 'refunded':
    className = 'pill danger';
    break;
  case 'partially settled':
    className = 'pill warning';
    break;
  case 'chargedback':
  case 'delinquent':
  case 'declined':
  case 'refused':
  case 'void':
    className = 'pill danger';
    break;
  case 'pending':
  case 'pending review':
  case 'pending po review':
  case 'upcoming':
  case 'processing':
  case 'draft':
  case 'extra charge requested':
  case 'extra charge pending':
    className = 'pill secondary';
    break;
  case 'due':
  case 'approval required':
  case 'requires approval':
  case 'signature required':
    className = 'pill warning';
    break;
  case 'need information':
    className = 'pill mustard';
    break;
  case 'awaiting remittance':
    className = 'pill secondary';
    break;
  case 'payoff in review':
    className = 'pill secondary';
    break;
  case 'payoff complete':
  case 'fully paid':
  case 'partially paid':
  case 'complete':
    className = 'pill success';
    break;
  case 'review purchase order':
    className = 'pill text-vartana-gold-160 bg-vartana-yellow-100';
    break;
  case 'need invoice':
    className = 'pill bg-vartana-gold-60';
    break;
  case 'awaiting acceptance':
    className = 'pill bg-vartana-light-purple';
    break;
  default:
    className = '';
  }
  return className;
};

export const getGraphColorOrderStatus = (status) => {
  const color = {};
  switch (status) {
  case 'approval_required':
    color.hex = '#3b89fe';
    color.fillClass = 'fill-pending';
    color.strokeClass = 'stroke-pending';
    break;
  case 'pending':
    color.hex = '#ffbb23';
    color.fillClass = 'fill-processing';
    color.strokeClass = 'stroke-processing';
    break;
  case 'checkout':
    color.hex = '#0d8143';
    color.fillClass = 'fill-approved';
    color.strokeClass = 'stroke-approved';
    break;
  case 'agreement':
    color.hex = '#FFCCCC';
    color.fillClass = 'fill-agreement';
    color.strokeClass = 'stroke-agreement';
    break;
  case 'invoice':
    color.hex = '#2dc26c';
    color.fillClass = 'fill-complete';
    color.strokeClass = 'stroke-complete';
    break;
  case 'financed':
    color.hex = '#2dc26c';
    color.fillClass = 'fill-complete';
    color.strokeClass = 'stroke-complete';
    break;
  case 'commenced':
    color.hex = '#2dc26c';
    color.fillClass = 'fill-complete';
    color.strokeClass = 'stroke-complete';
    break;
  default:
    color.hex = '#000000';
    color.fillClass = 'fill-black';
    color.strokeClass = 'stroke-black';
  }
  return color;
};

export const formatDate = (dateString = '') => {
  let formatted = '';
  if (dateString === null) {
    return formatted;
  }
  const year = moment(dateString).format('yyyy');
  if (year === moment().format('yyyy')) {
    formatted = moment(dateString).format('MMM D');
  } else {
    formatted = moment(dateString).format('MM/DD/YY');
  }
  return formatted;
};

export const pstMoment = (...args) => moment(...args).tz('America/Los_Angeles');

export const calculateChecksum = (file) => {
  return new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) reject(error);
      else resolve(checksum);
    });
  });
};

export const calculateFilesMetadata = (files) => {
  return new Promise((resolve, reject) => {
    const pendingJobs = files.map((file) => calculateChecksum(file));
    Promise.all(pendingJobs)
      .then((checksums) => {
        return files.map((file, index) => ({
          filename: file.name,
          byteSize: file.size,
          checksum: checksums[index],
          contentType: file.type,
        }));
      })
      .then((filesMetadata) => {
        resolve(filesMetadata);
      })
      .catch((error) => reject(error));
  });
};

export const isWidget = widgetBaseUrls.some((el) =>
  window.location.pathname.includes(el)
);

export const getAPIURL = () => {
  const location = window.location.hostname;
  const domainName = location.includes('com')
    ? process.env.REACT_APP_API_NEW_URL
    : process.env.REACT_APP_API_URL;
  if (isWidget) return `${domainName}/crm`;
  return `${domainName}/vendor`;
};

export const getWebsiteURL = () => {
  return process.env.REACT_APP_WEBSITE_URL;
};

export function showToast(type, message) {
  if (type === 'success') {
    toast.success(({ toastProps }) => (
      <CustomNotification type={toastProps.type} message={message} />
    ));
  }
  if (type === 'error') {
    toast.error(({ toastProps }) => (
      <CustomNotification type={toastProps.type} message={message} />
    ));
  }
  if (type === 'warning') {
    toast.warning(({ toastProps }) => (
      <CustomNotification type={toastProps.type} message={message} />
    ));
  }
}

export const showErrorToast = (message = '') => {
  showToast('error', message || 'Something went wrong. Please try again later.');
};

export const getModalPlacementString = (windowSize) => {
  const left = (windowSize.innerWidth - 500) / 2;
  const top = (windowSize.innerHeight - 500) / 2;
  return `width=500,height=600,left=${left},top=${top}`;
};

// Convert a number like 0.1234 into 12.34
export const rateToPercent = (rate) => (rate * 100).toFixed(2);

export function convertLocalDateToUTCIgnoringTimezone(date) {
  const timestamp = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  );

  return new Date(timestamp);
}

export function convertUTCToLocalDateIgnoringTimezone(utcDate) {
  return new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate(),
    utcDate.getUTCHours(),
    utcDate.getUTCMinutes(),
    utcDate.getUTCSeconds(),
    utcDate.getUTCMilliseconds()
  );
}

export function humanTimeFormat(dateString) {
  return dateString ? moment(dateString).format('MMM D, YYYY h:mmaz') : '';
}

export function humanPhoneFormat(phoneString) {
  if (phoneString)
    return phoneString
      .replace(/\D/g, '')
      .replace(/(\d*)(\d{3})(\d{3})(\d{4})$/, (s, a, b, c, d) => `+${a} (${b}) ${c}-${d}`)
      .replace(/\+(1\b|\s)\s*/, '');
  return null;
}

export function humanDateFormat(dateString) {
  return dateString ? moment(dateString).format('MMM D, Y') : '';
}

export function getUSDateFormat(dateString) {
  if (dateString?.includes('@')) {
    return moment(dateString, 'MMM D, YYYY @ h:mmaz').format('MM/DD/YYYY');
  }
  if (dateString) {
    return moment(dateString).format('MM/DD/YYYY');
  }
  return '';
}
export function getUSDateTimeFormat(dateTimeString) {
  if (dateTimeString?.includes('@')) {
    return moment(dateTimeString, 'MMM D, YYYY @ h:mmaz').format('MM/DD/YYYY  h:mmaz');
  }
  return '';
}
/**
 * When numberOfDays is -ve || 0,  return value is today.
 * When numberOfDays is 1, return value is tomorrow.
 * When numberOfDays is +ve, return value is the numberOfDays concat with 'days'.
 */
export function getTextForNumberOfDays(numberOfDays) {
  let text = '';
  if (numberOfDays < 1) {
    text = 'today';
  } else if (numberOfDays === 1) {
    text = 'tomorrow';
  } else {
    text = `${numberOfDays} days`;
  }
  return text;
}

export function humanizeName(name) {
  return name ? capitalize(name.replaceAll('_', ' ')) : '';
}

export function formatCapital(title) {
  return capitalize(title);
}

/**
 * Capitalizes the first letter of each word in the text and converts the rest to lowercase.
 *
 * @param {string} text The text to be formatted.
 * @returns {string} The formatted text.
 */
export function titleize(string) {
  return startCase(toLower(string));
}

/**
 * Removes trailing '.00' from a string.
 *
 * @param {string} text The input string.
 * @returns {string} The modified string with trailing '.00' removed.
 */
export function removeTrailingZeros(text) {
  // Check if the input is a string
  if (typeof string !== 'string') {
    // Return the original input if it's not a string
    return text;
  }

  // Replace '.00' with an empty string to remove it
  return text.replace('.00', '');
}

export function diffMonths(startDate, endDate) {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  const startDateString = moment(startDateObj).format('YYYY-MM-DD');
  const endDateString = moment(endDateObj).format('YYYY-MM-DD');

  return moment(endDateString).diff(moment(startDateString), 'months', true);
}

export function roundInTerms(number) {
  return Math.ceil(number / 12) * 12;
}

export function getAllowedTerms(terms) {
  return terms.map((term) => {
    return { value: term, label: term === 0 ? 'Pay in full' : `${term} months` };
  });
}

export function paymentFrequencyLabel(frequency) {
  return `${frequency === 'balloon' ? '' : capitalize(frequency)} Payment`;
}

export function paymentTermValue(frequency, value, formattedValue) {
  if (frequency === 'balloon') return value ? formattedValue : '-';
  return formattedValue;
}

export async function fetchJobTitles() {
  const response = await fetch(`${getAPIURL()}/utils/signer-titles`, {
    credentials: 'include',
  });
  if (response.ok) return response.json();
  throw new Error(`[fetchJobTitles]: Response: ${await response.text()}`);
}

export function valueChange(initial, updated) {
  return JSON.stringify(initial) !== JSON.stringify(updated);
}

/**
 * For local env, return values from feature_flags.json
 * For all other environments, return values from LaunchDarkly
 */
export function useVartanaFeatureFlags() {
  const flags = useFlags();
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    return featureFlags;
  }

  return flags;
}

/**
 * Takes in a list of documents & merges them into a single PDF.
 * It combines all pages of each document into a single PDF.
 *
 * @param {string[]} documents - List of Base64 encoded strings
 * @param {string} title - Title of document
 * @returns {Promise<string>} Base64 encoded string for final PDF
 */
export async function mergePDFs(documents, title = 'Document') {
  const mergedPdf = await PDFDocument.create();

  const pdfPromises = documents.map(async (document) => {
    return PDFDocument.load(`data:application/pdf;base64,${document}`);
  });

  const pdfs = await Promise.all(pdfPromises);
  const pdfCopyPromises = pdfs.map((pdf) => {
    return mergedPdf.copyPages(pdf, pdf.getPageIndices());
  });

  const copiedPDfs = await Promise.all(pdfCopyPromises);
  copiedPDfs.forEach((copiedPdf) => copiedPdf.forEach((page) => mergedPdf.addPage(page)));

  mergedPdf.setTitle(title);
  mergedPdf.setSubject(title);
  mergedPdf.setAuthor('Vartana');
  return mergedPdf.saveAsBase64({ dataUri: true });
}

/**
 * Check if company can act as a pure or hybrid vendor.
 *
 * @param {object} company
 * @param {string} company.sellerType
 * @returns {boolean}
 */
export function isVendor(company) {
  return (
    company?.sellerType === SELLER_TYPE.VENDOR ||
    company?.sellerType === SELLER_TYPE.VENDOR_RESELLER
  );
}

/**
 * Check if company can act as a pure or hybrid reseller.
 *
 * @param {object} company
 * @param {string} company.sellerType
 * @returns {boolean}
 */
export function isReseller(company) {
  return (
    company?.sellerType === SELLER_TYPE.RESELLER ||
    company?.sellerType === SELLER_TYPE.VENDOR_RESELLER
  );
}

/**
 * This Helper Function reformats the contract length in following way
 * input: "12 months ends on (11/10/2023)"
 * output: "12 months"
 * input: "12 months ends on (11/10/2023) (co-term)"
 * output: "12 months (co-term)"
 * note: This is for the case of non rollover orders where end date is not shown
 */
export const reformatContractLengthLabel = (label) => {
  // Use a regular expression to match the numeric part
  const regex = /(\d+) months/;
  const match = label.match(regex);

  // Check if there is a match
  if (match && match[1]) {
    return label.includes('(co-term)')
      ? `${match[1]} months (co-term)`
      : `${match[1]} months`;
  }
  return label.includes('(co-term)') ? `${label} (co-term)` : label;
};

export const getPageUrl = ({
  crmOpportunityId = '',
  customerName = '',
  customerNumber = '',
  expandedApp = false,
  isNonVartanaOrder = false,
  orderNumber = '',
  otherParams = '',
  page,
  showHelp = false,
  moreInfoStep = 'authorized-signer',
}) => {
  // this function is used to generate page URLs for VD and expanded app's pages
  switch (page) {
  case 'customerSummary':
    return `/dashboard/customers/${customerNumber}/summary`;
  case 'moreInfo':
    if (expandedApp)
      return `/forms/more-info/${moreInfoStep}?companyNumber=${customerNumber}${otherParams}`;
    return `/dashboard/customers/${customerNumber}/more-info/${moreInfoStep}`;
  case 'moreDetails':
    return `/forms/more-details?customerNumber=${customerNumber}`;
  case 'shareLink':
    return `/forms/share-link?customerNumber=${customerNumber}&crmOpportunityId=${crmOpportunityId}${otherParams}`;
  case 'resellerInvite':
    if (expandedApp)
      return `/forms/resellers/invite${otherParams}&customerNumber=${customerNumber}&customerName=${customerName}`;
    return `/dashboard/resellers/invite?customerNumber=${customerNumber}&customerName=${customerName}`;
  case 'modifyTerms':
    if (expandedApp)
      return `/forms/request-higher-limit/v2${otherParams}&customerNumber=${customerNumber}`;
    return `/dashboard/customers/${customerNumber}/request-higher-limit/v2`;
  case 'orderSummary':
    if (expandedApp)
      return `/forms/summary?customerNumber=${customerNumber}&crmOpportunityId=${crmOpportunityId}&orderNumber=${orderNumber}&tab=details${
          showHelp && '&help=true'
        }`;
    return `/dashboard/orders/${orderNumber}/summary`;
  case 'createOrder':
    if (expandedApp)
      return `/forms/order${otherParams}&customerNumber=${customerNumber}${
          isNonVartanaOrder ? '&isSignatureOnly=true' : ''
        }`;
    return `/dashboard/orders/new${otherParams}`;
  case 'trackOrder':
    return `/forms/summary?customerNumber=${customerNumber}&crmOpportunityId=${crmOpportunityId}&orderNumber=${orderNumber}&tab=track`;
  case 'getHelp':
    return `/forms/help?customerNumber=${customerNumber}&help=true`;
  case 'confirmation':
    return '/forms/confirmation/v2';
    // we can list more cases here as needed
  default:
    return '';
  }
};

export const isRenderedFromHubspot = () => {
  const { search } = window.location;
  const useQueryParams = new URLSearchParams(search);
  return useQueryParams.get('appName') === APP_NAMES.HUBSPOT;
};

/**
 * Checks if the widget is being rendered in Salesforce.
 * @returns {boolean} True if the widget is in Salesforce, false otherwise.
 */
export const isSalesforceWidget = () => {
  const { search, pathname } = window.location;
  const useQueryParams = new URLSearchParams(search);
  return useQueryParams.get('appName') !== APP_NAMES.HUBSPOT && pathname === PAGES.WIDGET;
};

export const allowFullstory = !isSalesforceWidget();

export const getFlagByCurrency = (currency) => {
  switch (currency) {
  case COUNTRY_TO_CURRENCY_MAP.US:
    return USAFlag;
  case COUNTRY_TO_CURRENCY_MAP.CA:
    return CanadaFlag;

  default:
    return '';
  }
};

export const isEmptyString = (str) => str === '';

export const moveKeys = (sourceObj, targetObj, keys) => {
  // this method is used to move keys from source obj to target obj
  const newSourceObj = { ...sourceObj };
  const newTargetObj = { ...targetObj };

  keys.forEach((key) => {
    if (key in newSourceObj) {
      newTargetObj[key] = newSourceObj[key];
      delete newSourceObj[key];
    }
  });

  return [newSourceObj, newTargetObj];
};

export const getCountryOptions = (supportedCountries) => {
  const countryOptions = [];
  supportedCountries.forEach((country) =>
    countryOptions.push({
      label: country === COUNTRY.US ? COUNTRY.US : countries.getCountry(COUNTRY.CA),
      value: country,
    })
  );
  return countryOptions;
};

export const getStateOptions = (supportedCountries, isSmall = false) => {
  const options = [];
  supportedCountries.forEach((country) => {
    const states = isSmall
      ? COUNTRY_NAME_TO_STATES_MAP[country].shortNames
      : COUNTRY_NAME_TO_STATES_MAP[country].fullNames;
    if (states) {
      options.push(...states);
    }
  });
  return options;
};

export const handleCountrySelect = async (
  { setValues, setErrors, setTouched, values },
  value
) => {
  // if the country was selected for the first time, we should not reset
  if (!values.country) return;

  // reset all address fields
  await setValues((otherValues) => ({
    ...otherValues,
    country: value,
    city: '',
    state: '',
    zip: '',
    street: '',
  }));

  // reset errors for empty fields
  await setErrors((otherErrors) => ({
    ...otherErrors,
    city: '',
    state: '',
    zip: '',
    street: '',
  }));

  // set touched to false for empty fields to avoid validation errors
  await setTouched((otherTouched) => ({
    ...otherTouched,
    city: false,
    state: false,
    zip: false,
    street: false,
  }));
};

export const getTermOptionsForCustomerCreation = (session, selectedCountry) => {
  const defaultTerms = getAllowedTerms(
    get(session, 'session.user.company.product.allowedDurations', [])
  );
  if (selectedCountry === COUNTRY.CA) {
    return defaultTerms.filter((term) => term.value);
  }
  return defaultTerms;
};

// this will remove customerNumber from the url if it is empty
export function removeEmptyCustomerNumber(url) {
  return url?.replace('customerNumber=&', '');
}

/**
 * Formats a given EIN (Employer Identification Number) by masking all but the last four digits.
 *
 * @param {string | null} value - The EIN value to be formatted. It can be a string or null.
 * @returns {string} - The formatted EIN with all but the last four digits masked.
 *
 * If the input value is null or empty, the function returns an empty string.
 * The function removes all non-digit characters from the input value using a regular expression.
 * Then, it constructs the masked EIN by concatenating five hidden characters and the last four digits of the cleaned value.
 * The hidden characters are repeated five times, and the slice method is used to get the last four digits of the cleaned value.
 */
export const formatMaskedEIN = (value) => {
  if (!value) return '';
  const masked =
    KEYS.HIDDEN.repeat(INPUT_MAX_LENGTHS.EIN_MASKED) +
    value.slice(-INPUT_MAX_LENGTHS.EIN_UNMASKED);
  return masked;
};
