import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { lowerCase } from 'lodash';
import { Typography } from '@vartanainc/design-system';
import { COUNTRY } from '../static';

/*
  Helper method to test unique properties in Yup arrays. To be used on Yup.array()
*/
// eslint-disable-next-line func-names
Yup.addMethod(Yup.array, 'unique', function (propertyName, message = '') {
  return this.test('unique', message, (list, ctx) => {
    const properties = list.map((el) => el[propertyName]?.trim());
    if (list.length === new Set(properties).size) return true;

    const { path, createError } = ctx;
    const duplicates = properties
      .map((propertyValue, index) => {
        if (properties.indexOf(propertyValue) !== index) return index;
        return null;
      })
      .filter((a) => a);

    return createError({
      path: `${path}.${duplicates.at(-1)}.${propertyName}`,
      message: message || `Duplicate ${lowerCase(propertyName)}.`,
    });
  });
});

export const sessionTypes = {
  ADMIN_USER: 'adminUser',
  VENDOR_USER: 'vendorUser',
};

export const oneWholeTwoDecimal = 9.99;

export const MIN_PASSWORD_LENGTH = 8;

export const MAX_TERM_ALLOWED = 72;

export const PLACEHOLDER_STRING = '--';
export const EIN_PLACEHOLDER = '__-_______';
export const DEFAULT_FORMATTED_PHONE = '+1 (___) ___-____';

export const HUBSPOT_QUERY_PARAM = 'appName=hubspot';
export const APPLICATION_MAX_UPLOAD_FILES = 5;
export const ORDER_PAY_IN_FULL_VALUE = 'balloon';

export const SPIFF_MODE = {
  fixed: 'fixed',
  none: 'none',
};

export const orderType = {
  pay_in_full: 'full_payment',
  installments: 'installment_plan',
  full_payment: 'direct',
};

export const orderTypeLabels = {
  full_payment: 'Defer payment',
  installment_plan: 'Installment plan',
  direct: 'Signature only',
};

export const contractLengthLabels = {
  monthly: 'Pay in full',
  quarterly: 'Installments',
  annually: 'Annually',
};

export const paymentFrequencyLabels = {
  balloon: 'Balloon',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  annual: 'Annually',
};

export const estimatedPaymentFrequencyLabels = {
  balloon: 'Balloon',
  monthly: '/ month',
  quarterly: '/ quarter',
  annual: '/ year',
};

export const paymentTermLabels = {
  0: 'Upon receipt',
  15: 'Net-15',
  30: 'Net-30',
  60: 'Net-60',
  90: 'Net-90',
  120: 'Net-120',
};

export const proposalFields = {
  startDate: 'startDate',
  contractLength: 'contractLength',
  paymentFrequency: 'paymentFrequency',
  paymentTerm: 'paymentTerm',
  vartanaFinancing: 'vartanaFinancing',
  subsidy: 'subsidy',
};

export const orderTypeMapping = {
  installment_plan: Object.values(proposalFields),
  full_payment: ['paymentTerm', 'vartanaFinancing', 'subsidy'],
  direct: ['paymentTerm'],
};

export const billingMethodsLabels = {
  ach: 'ACH',
  invoice: 'Invoice',
  credit_card: 'Credit card',
};

export const orderScreens = {
  proposals: 'proposals',
  reseller: 'reseller',
  authorizedSigner: 'authorizedSigner',
};

export const summaryModes = {
  createOrder: 'createOrder',
  orderDetails: 'orderDetails',
};

export const fullBuyoutFields = {
  isFullBuyout: 'isFullBuyout',
  payoffDiscount: 'payoffDiscount',
  netPayoff: 'netPayoff',
};

export const installmentSummaryFields = {
  totalContractValue: 'totalContractValue',
  availableCredit: 'availableCredit',
  paymentDue: 'paymentDue',
  paymentTerm: 'paymentTerm',
  startEndDate: 'startEndDate',
  payments: 'payments',
  estimatedPayments: 'estimatedPayments',
  documentationFee: 'documentationFee',
  netPayout: 'netPayout',
  processingFee: 'processingFee',
  totalFees: 'totalFees',
  currency: 'currency',
  ...fullBuyoutFields,
};

export const fullPaymentSummaryFields = {
  totalContractValue: 'totalContractValue',
  availableCredit: 'availableCredit',
  paymentDue: 'paymentDue',
  paymentTerm: 'paymentTerm',
  totalPayment: 'totalPayment',
  netPayout: 'netPayout',
  processingFee: 'processingFee',
  documentationFee: 'documentationFee',
  totalFees: 'totalFees',
  endDate: 'endDate',
  ...fullBuyoutFields,
};

export const vendorPayoutFields = {
  processingFee: 'processingFee',
  netPayout: 'netPayout',
  documentationFee: 'documentationFee',
  totalFees: 'totalFees',
  currency: 'currency',
};

const payInFullWithoutVartanaFinancing = {
  totalContractValue: 'totalContractValue',
  paymentDue: 'paymentDue',
  endDate: 'endDate',
};

const payInFullWithVartanaFinancing = {
  totalContractValue: 'totalContractValue',
  availableCredit: 'availableCredit',
  paymentDue: 'paymentDue',
  processingFee: 'processingFee',
  totalPayment: 'totalPayment',
  documentationFee: 'documentationFee',
  totalFees: 'totalFees',
  netPayout: 'netPayout',
  endDate: 'endDate',
  ...fullBuyoutFields,
};

const installmentsWithoutVartanaFinancing = {
  totalContractValue: 'totalContractValue',
  paymentTerm: 'paymentTerm',
  paymentDue: 'paymentDue',
  startEndDate: 'startEndDate',
  payments: 'payments',
  estimatedPayments: 'estimatedPayments',
};

const installmentsWithVartanaFinancing = {
  totalContractValue: 'totalContractValue',
  availableCredit: 'availableCredit',
  paymentTerm: 'paymentTerm',
  documentationFee: 'documentationFee',
  paymentDue: 'paymentDue',
  startEndDate: 'startEndDate',
  payments: 'payments',
  estimatedPayments: 'estimatedPayments',
  processingFee: 'processingFee',
  netPayout: 'netPayout',
  totalFees: 'totalFees',
  ...fullBuyoutFields,
};

export const vartanaFinancingMap = {
  financing: {
    full_payment: Object.values(payInFullWithVartanaFinancing),
    installment_plan: Object.values(installmentsWithVartanaFinancing),
    direct: {}, // Full payment/signature order not allowed for financed order
  },
  non_financing: {
    full_payment: Object.values(payInFullWithoutVartanaFinancing),
    installment_plan: Object.values(installmentsWithoutVartanaFinancing),
    direct: {}, // Hiding summary for signature order
  },
};

export const orderTypeSummaryMapping = {
  installment_plan: Object.values(installmentSummaryFields),
  full_payment: Object.values(fullPaymentSummaryFields),
  direct: Object.values(fullPaymentSummaryFields),
};

export const orderTypeSummaryVartanaFinancingMapping = {
  installment_plan: Object.values(installmentSummaryFields),
  full_payment: Object.values(fullPaymentSummaryFields),
  direct: Object.values(fullPaymentSummaryFields),
};

export const labelValueField = (label, value, link = null, emptyField = false) => (
  <div
    className={`flex flex-col flex-1 gap-1 ${
      emptyField ? 'empty-field' : 'populated-field'
    }`}
  >
    <p className="break-all vartana-p-small-bold text-vartana-gray-50">{label}</p>
    {link ? (
      <Link to={link}>
        <p className="break-all vp-text-link-bold text-vartana-blue-60">{value}</p>
      </Link>
    ) : (
      <Typography
        variant="paragraph14"
        color="color-black-100"
        className="break-all whitespace-pre-line"
      >
        {value}
      </Typography>
    )}
  </div>
);

export const editOrderType = {
  editOrder: 'editOrder',
  editAuthSigner: 'editAuthSigner',
};

export const paymentOptions = {
  financing: 'financing',
  direct: 'direct',
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const widgetBaseUrls = ['crm', 'forms'];

export const FIELD_REQUIRED_MSG = 'This field is required';

export const TOAST_SUCCESS_STATUS = 'success';
export const CHANGE_REQUEST_SUCCESS_MESSAGE =
  'We have received your request and will respond with a decision shortly.';

export const appVariants = {
  vendor: 'vendor',
  widget: 'widget',
};

export const orderStates = {
  pending: 'pending',
  credit_review: 'credit_review',
  ready_to_checkout: 'ready_to_checkout',
  requires_approval: 'requires_approval',
  checkout: 'checkout',
  verification: 'verification',
  payment_options: 'payment_options',
  payment_method: 'payment_method',
  personal_guarantee: 'personal_guarantee',
  signatures: 'signatures',
  additional_documents: 'additional_documents',
  counter_signature: 'counter_signature',
  manual_signature: 'manual_signature',
  need_invoice: 'need_invoice',
  awaiting_acceptance: 'awaiting_acceptance',
  ready_to_accept: 'ready_to_accept',
  ready_to_commence: 'ready_to_commence',
  ready_to_fund: 'ready_to_fund',
  partially_paid: 'partially_paid',
  fully_paid: 'fully_paid',
  completed: 'completed',
  canceled: 'canceled',
  expired: 'expired',
  review_purchase_order: 'review_purchase_order',
  pending_po_review: 'pending_po_review',
};

export const postCheckoutStates = [
  orderStates.pending_po_review,
  orderStates.review_purchase_order,
  'need_invoice',
  'awaiting_acceptance',
  'ready_to_accept',
  'ready_to_commence',
  'ready_to_fund',
  'partially_paid',
  'fully_paid',
  'completed',
];

export const orderNoDocFeeUpdate = [
  'checkout',
  'payment_options',
  'payment_method',
  'personal_guarantee',
  'signatures',
  'additional_documents',
  'counter_signature',
  'manual_signature',
  'need_invoice',
  'awaiting_acceptance',
  'ready_to_accept',
  'ready_to_commence',
  'ready_to_fund',
  'partially_paid',
  'fully_paid',
  'completed',
  'canceled',
];

export const editNotAllowedStates = [
  'signatures',
  'counter_signature',
  'manual_signature',
  orderStates.pending_po_review,
  orderStates.review_purchase_order,
  'need_invoice',
  'completed',
  'canceled',
  'vendor_signature',
  'signatures',
  'expired',
  'awaiting_acceptance',
  'ready_to_accept',
  'ready_to_commence',
  'ready_to_fund',
  'partially_paid',
  'fully_paid',
];

export const hideDropdownStates = [
  'payment_method',
  'personal_guarantee',
  'signatures',
  'additional_documents',
  'counter_signature',
  'manual_signature',
  orderStates.pending_po_review,
  orderStates.review_purchase_order,
  'need_invoice',
  'awaiting_acceptance',
  'ready_to_accept',
  'ready_to_commence',
  'ready_to_fund',
  'partially_paid',
  'fully_paid',
  'completed',
  'canceled',
  'vendor_signature',
  'expired',
];

export const PROPOSAL_SUMMARY_FIELDS_LABELS = {
  TCV: 'Total contract value',
  AVAILABLE_CREDIT: 'Available amount',
  CONTRACT_LENGTH: 'Contract length',
  BILLING_FREQUENCY: 'Billing frequency',
  NET_TERMS: 'Net terms',
  DATES: 'Start and end date',
  ESTIMATED_PAYMENT: 'Estimated payment',
  DOCUMENTATION_FEE: 'Documentation fee',
  PAYOFF_DISCOUNT: 'Payoff discount',
  NET_PAYOFF: 'Net payoff',
  PROCESSING_FEE: 'Processing fee',
  TOTAL_FEES: 'Total fees',
  ORDER_PAYOUT: 'Order payout',
  CURRENCY: 'Currency',
};

export const loanDecision = {
  approved: 'approved',
  canceled: 'canceled',
  declined: 'declined',
  pendingReview: 'pending_review',
  needInformation: 'need_information',
};

export const downloadStatuses = {
  DEFAULT: 'default',
  DOWNLOADING: 'downloading',
  DOWNLOADED: 'downloaded',
};

export const appraisalStatuses = {
  applicationRequested: 'applicationRequested',
};

export const calculatorType = {
  fullPayment: 'fullPayment',
  installment: 'installment',
  availableCredit: 'availableCredit',
  approvedPaymentPlans: 'approvedPaymentPlans',
  orderExist: 'orderExist',
};

export const phoneFieldValidation = Yup.string()
  .nullable()
  .test('len', 'Invalid phone number', (val) => {
    if (val) {
      if (val === '+1 (___) ___-____') {
        return true;
      }
      if (val.includes('_')) {
        return false;
      }
    }
    return true;
  });

export const requiredPhoneFieldValidation = Yup.string()
  .required('This field is required')
  .test('empty', 'This field is required', (val) => {
    // adding this test to check if the phone number is empty
    // if the phone number is empty, the value will be the default formatted phone number
    // as formatted phone number field returns that when it is empty
    const isPhoneEmpty = val && val === DEFAULT_FORMATTED_PHONE;
    return !isPhoneEmpty;
  })
  .test('len', 'Invalid phone number', (val) => {
    const isInvalidPhoneNumber =
      val && val.includes('_') && val !== DEFAULT_FORMATTED_PHONE;
    return !isInvalidPhoneNumber;
  });

export const accountExecutiveTypes = {
  customer: 'customer',
  order: 'order',
};

export const commonRegex = {
  atLeastOneNumber: /\d/,
  atLeastOneSpecialChar: /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
  atLeastOneUpperAndLowerCaseChar: /(?=.*[a-z])(?=.*[A-Z])\w+/,
  atLeastOneAlphabet: /^(?=.*[a-zA-Z]).+$/,
  email: /^\w+([.-]?\w+)*(\+\w+)?@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  groupedEmail: /[\w\d.-]+(?:\+[\w\d]+)?@[\w\d.-]+\.[\w\d.-]+/g,
  allNumbers: /[^0-9.-]+/g,
  // einFormat validates the field to be in a format like 99-9999999 (2 digits followed by hyphen and 7 digits)
  einFormat: /^\d{2}-\d{7}$/,
  isNumber: /^\d$/,
  atLeastOneCapitalAlphabet: /[A-Z]/,
  atLeastOneSmallAlphabet: /[a-z]/,
  alphabetsAndSpaces: /^[a-zA-Z\s]+$/,
  alphaNumericAndSpaces: /^[a-zA-Z0-9\s]+$/,
  fiveDigitsOnly: /^\d{5}$/,
  usZipRegex: /^\d{5}(-\d{4})?$/,
  // Canadian postal code format A1A 1A1 or a1a 1a1.
  // Allowing both upper and lower case for better user experience
  caPostalRegex: /^[a-zA-Z]\d[a-zA-Z] \d[a-zA-Z]\d$/,
  alphabetsAndSingleSpace: /^[a-zA-ZÀ-ÖØ-öø-ÿ-]+(\s[a-zA-ZÀ-ÖØ-öø-ÿ-]+)*\s?$/,
};

export const FIELD_INVALID_MESSAGES = {
  firstName: 'Please enter a valid first name',
  lastName: 'Please enter a valid last name',
};

export const ERROR_MESSAGE = {
  pageNotFound: 'Page not found',
  accessDenied: 'Access denied',
  somethingWentWrong: 'Something went wrong',
  editUnsucessful:
    'Edit request is unsuccessful. At least one field needs to be different for order details to be updated.',
};
export const TOAST_TYPE = {
  error: 'error',
  warning: 'warning',
  success: 'success',
};
// Rollbar alerts which need to be ignored
export const SPAM_ALERTS = ['Failed to fetch'];
export const THRESHOLD_AMOUNT = 500_000;
// TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
export const THRESHOLD_FORMATTED = '$500,000';
export const launchDarklyDefaultContextKey = 'default-context-key';
export const PROGEN_CTA = {
  createOrder: 'create_order',
  customerCheckout: 'check_out_now',
  getStarted: 'get_started',
  modifyTerms: 'modify_terms',
  contactHelpDesk: 'contact_help_desk',
  requestFromCustomer: 'request_from_customer',
  submitForm: 'submit',
  viewOrder: 'view_order',
  viewDocuments: 'view_documents',
  downloadProposal: 'download_proposal',
  updateRates: 'update_rates',
  uploadInfo: 'upload_information',
  resendRequest: 'resend_request',
  resendOrder: 'resend_order',
  inviteReseller: 'invite_reseller',
  generateNewTerms: 'generate_new_terms',
  creditInfo: 'credit_information',
  submitInformation: 'submit_information',
  viewCreditDecision: 'view_credit_decision',
  learnMore: 'learn_more',
  viewDecisionDetail: 'view_decision_detail',
  trackRequest: 'track_request',
  cancelRequest: 'cancel_request',
  approveOrder: 'approve',
  uploadInvoice: 'upload_invoice',
  provideInformation: 'provide_information',
  reviewAndApprove: 'review_and_approve',
  reviewAndSign: 'review_and_sign',
  downloadStatement: 'download_statement',
  viewPaymentSchedule: 'view_payment_schedule',
  seeAgreement: 'see_agreement',
};

// Seller types
export const SELLER_TYPE = {
  VENDOR: 'vendor', // Pure vendor
  RESELLER: 'reseller', // Pure reseller
  VENDOR_RESELLER: 'vendor_reseller', // Hybrid reseller
};

// Buyer types
export const BUYER_TYPE = {
  DIRECT: 'direct', // Vendor's buyer
  INDIRECT: 'indirect', // Reseller's buyer
};

export const PASSWORD_ERRORS = {
  lengthError: 'Contains at least 8 characters',
  numberError: 'Contains 1 number',
  casingError: 'Contains 1 uppercase and 1 lowercase character',
};

export const ORDER_FINANCING_TYPES = {
  FINANCING: 'financing',
  NON_FINANCING: 'non_financing',
};

export const SALES_TAX_AND_SHIPPING_ERROR_MESSAGE =
  'Installment payments may change based on sales tax and shipping costs.';

export const CARD_VARIANTS = {
  variable: 'variable',
  fullwidth: 'fullwidth',
};

export const CONFIRMATION_SCREEN_VARIANTS = {
  CHANGE_REQUEST: 'change_request',
  SIGNATURE_ORDER_DIRECT: 'signature_order_direct',
  SIGNATURE_ORDER_REVIEW: 'signature_order_review',
};

export const WIDGET_WIDTH_VARIANTS = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

export const PROGEN_TAB = {
  signatures: 'sendForSignature',
  defer: 'deferPayment',
  installment: 'offerInstallment',
};

export const TABLE_EMPTY_PLACEHOLDER = '--';

export const orderNoEditStates = [
  'signatures',
  'counter_signature',
  'manual_signature',
  orderStates.pending_po_review,
  orderStates.review_purchase_order,
  'need_invoice',
  'awaiting_acceptance',
  'ready_to_accept',
  'ready_to_commence',
  'ready_to_fund',
  'partially_paid',
  'fully_paid',
  'completed',
  'canceled',
  'vendor_signature',
  'signatures',
  'expired',
];

// Zip code validation schema
// US zip code should be 5 digits
// Canadian zip code should be in the format A1A 1A1
export const zipSchema = Yup.string()
  .required('Zip code is required')
  .when(['country'], {
    is: COUNTRY.US,
    then: Yup.string().matches(commonRegex.usZipRegex, 'Invalid US zip code'),
    otherwise: Yup.string(),
  })
  .when(['country'], {
    is: COUNTRY.CA,
    then: Yup.string().matches(commonRegex.caPostalRegex, 'Invalid Canadian zip code'),
    otherwise: Yup.string(),
  });

// this is required to be used in the case when a vendor is not yet onboarded
// and we need to all options to the user.
// add more as required. to be fetched from BE in future.
export const ALL_COUNTRIES_SHORTHAND = ['US', 'CA'];

export const US_ZIP_PLACEHOLDER = 'XXXXX';
export const CA_POSTAL_PLACEHOLDER = 'A1A 1A1';

export const COUNTRY_NAME_TO_ZIP_PLACEHOLDER = {
  US: US_ZIP_PLACEHOLDER,
  CA: CA_POSTAL_PLACEHOLDER,
};

export const MIN_ZIP_LENGTH = 5;
export const EIN_FIELD_LENGTH = 9;
export const TRANSIT_NUMBER_LENGTH = 5;
export const INSTITUTION_NUMBER_LENGTH = 3;

export const INPUT_MAX_LENGTHS = {
  EIN: 10, // EIN length with hyphen (-)
  EIN_MASKED: 5, // number of characters in EIN to hide (without hyphen -)
  EIN_UNMASKED: 4, // number of characters in EIN to show
};

export const KEYS = {
  HIDDEN: '●',
};

export const PAGES = {
  WIDGET: '/crm/widget',
  // more pages to be added here
};

export const DOC_TYPES = {
  CUSTOM_AGREEMENT: 'custom_agreement',
};

export const uploadVendorInvoiceTitle = 'Upload invoice';
export const vendorInvoiceSubmittedText = 'Vendor invoice submitted!';

// this will be used to show success modal when payment info is received
export const MODAL_CONSTANTS = {
  success: 'success',
  paymentInfo: 'info',
};

export const INVOICE_UPLOAD_TOAST_MESSAGE = 'Information submitted successfully.';
export const CUSTOMER_INVOICES_TAB_SUB_TEXT =
  'Data will show up once an order is commenced.';
